import { motion } from "framer-motion";
import { Card } from "@/components/ui/card";
import { MapPin, Star, Heart, Wifi, Coffee, Utensils } from "lucide-react";
import { Link } from "react-router-dom";

interface HotelCardProps {
  id?: string;
  image: string;
  title: string;
  description: string;
  tags: string[];
  rating?: number;
  location?: string;
  amenities?: string[];
  priceRange?: string;
}

const HotelCard = ({ 
  id = "1",
  image, 
  title, 
  description, 
  tags,
  rating = 4.5,
  location = "Islamabad, Pakistan",
  amenities = [],
  priceRange
}: HotelCardProps) => {
  return (
    <Link to={`/hotels/${id}`}>
      <motion.div
        whileHover={{ y: -5 }}
        transition={{ duration: 0.2 }}
        className="h-full"
      >
        <Card className="overflow-hidden group cursor-pointer h-full bg-white/80 backdrop-blur-sm hover:bg-white transition-colors duration-300">
          <div className="relative aspect-[4/3]">
            <img
              src={image}
              alt={title}
              className="w-full h-full object-cover group-hover:scale-105 transition-transform duration-500"
            />
            <button 
              className="absolute top-4 right-4 p-2.5 rounded-full bg-white/20 backdrop-blur-sm hover:bg-white/40 transition-colors duration-300 group"
              onClick={(e) => {
                e.preventDefault();
                // Add to favorites logic here
              }}
            >
              <Heart className="w-5 h-5 text-white group-hover:text-primary transition-colors duration-300" />
            </button>
            <div className="absolute bottom-0 inset-x-0 h-2/3 bg-gradient-to-t from-black/80 via-black/50 to-transparent" />
          </div>
          <div className="p-6">
            <div className="flex items-start justify-between mb-4">
              <div>
                <h3 className="text-xl font-serif font-semibold mb-2 group-hover:text-primary transition-colors duration-300">{title}</h3>
                <div className="flex items-center gap-2 text-sm text-gray-600">
                  <MapPin className="w-4 h-4" />
                  <span>{location}</span>
                </div>
              </div>
              <div className="flex items-center gap-1 bg-primary/10 text-primary px-3 py-1.5 rounded-full">
                <Star className="w-4 h-4 fill-current" />
                <span className="font-medium">{rating}</span>
              </div>
            </div>
            <p className="text-sm text-gray-600 mb-4 line-clamp-2">{description}</p>
            {amenities.length > 0 && (
              <div className="flex gap-3 mb-4">
                {amenities.includes('wifi') && (
                  <Wifi className="w-4 h-4 text-gray-500" />
                )}
                {amenities.includes('restaurant') && (
                  <Utensils className="w-4 h-4 text-gray-500" />
                )}
                {amenities.includes('coffee') && (
                  <Coffee className="w-4 h-4 text-gray-500" />
                )}
              </div>
            )}
            {priceRange && (
              <div className="text-sm font-medium text-primary mb-4">
                {priceRange}
              </div>
            )}
            <div className="flex flex-wrap gap-2">
              {tags.map((tag) => (
                <span
                  key={tag}
                  className="px-3 py-1 text-xs rounded-full bg-secondary/50 text-text font-medium hover:bg-secondary transition-colors duration-300"
                >
                  {tag}
                </span>
              ))}
            </div>
          </div>
        </Card>
      </motion.div>
    </Link>
  );
};

export default HotelCard;