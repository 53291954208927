import { Twitter, Instagram, Globe } from "lucide-react";
import { SocialLinks } from "@/types/blog";

interface BlogSocialLinksProps {
  socialLinks: SocialLinks | null;
}

export const BlogSocialLinks = ({ socialLinks }: BlogSocialLinksProps) => {
  if (!socialLinks) return null;

  return (
    <div className="flex gap-4">
      {socialLinks.twitter && (
        <a
          href={`https://twitter.com/${socialLinks.twitter}`}
          className="text-gray-600 hover:text-primary transition-colors hover:scale-110 transform"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Twitter className="w-5 h-5" />
        </a>
      )}
      {socialLinks.instagram && (
        <a
          href={`https://instagram.com/${socialLinks.instagram}`}
          className="text-gray-600 hover:text-primary transition-colors hover:scale-110 transform"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Instagram className="w-5 h-5" />
        </a>
      )}
      {socialLinks.website && (
        <a
          href={socialLinks.website.startsWith('http') ? socialLinks.website : `https://${socialLinks.website}`}
          className="text-gray-600 hover:text-primary transition-colors hover:scale-110 transform"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Globe className="w-5 h-5" />
        </a>
      )}
    </div>
  );
};