import { motion } from "framer-motion";

export const BlogsHeader = () => {
  return (
    <div className="max-w-2xl mx-auto text-center mb-12">
      <motion.h1
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="text-4xl md:text-5xl font-serif font-bold mb-4"
      >
        Travel Stories
      </motion.h1>
      <motion.p
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.1 }}
        className="text-gray-600 text-lg"
      >
        Discover inspiring travel tales and expert tips from our community
      </motion.p>
    </div>
  );
};