import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { Facebook, Twitter, Instagram, Youtube, MapPin, Phone, Mail, ExternalLink } from "lucide-react";

const Footer = () => {
  const currentYear = new Date().getFullYear();
  
  const socialLinks = [
    { icon: Facebook, href: "https://facebook.com/travl.pk", label: "Facebook" },
    { icon: Twitter, href: "https://twitter.com/travl_pk", label: "Twitter" },
    { icon: Instagram, href: "https://instagram.com/travl.pk", label: "Instagram" },
    { icon: Youtube, href: "https://youtube.com/travl_pk", label: "Youtube" }
  ];

  const quickLinks = [
    { to: "/destinations", label: "Explore Destinations" },
    { to: "/categories", label: "Travel Categories" },
    { to: "/blog", label: "Travel Stories" },
    { to: "/contact", label: "Get in Touch" }
  ];

  const legalLinks = [
    { to: "/privacy-policy", label: "Privacy Policy" },
    { to: "/terms-of-service", label: "Terms of Service" },
    { to: "/cookie-policy", label: "Cookie Policy" }
  ];

  const contactInfo = [
    { icon: MapPin, text: "Islamabad, Pakistan" },
    { icon: Phone, text: "+92 300 1234567" },
    { icon: Mail, text: "hello@travl.pk" }
  ];

  return (
    <footer className="relative bg-gradient-to-br from-text to-text-dark text-white/90">
      <div className="absolute inset-0 overflow-hidden">
        <div className="absolute -top-24 -right-24 w-48 h-48 bg-primary/10 rounded-full blur-3xl" />
        <div className="absolute -bottom-24 -left-24 w-48 h-48 bg-secondary/10 rounded-full blur-3xl" />
      </div>

      <div className="container mx-auto px-4 relative">
        <div className="py-16">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-12">
            {/* Brand Section */}
            <motion.div 
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              className="space-y-6 text-left"
            >
              <Link to="/" className="inline-block" aria-label="Go to homepage">
                <h3 className="font-serif text-3xl font-bold">
                  <span className="text-primary">Travl</span>
                  <span className="text-white">.pk</span>
                </h3>
              </Link>
              <p className="text-white/80 leading-relaxed text-left">
                Your AI-powered guide to Pakistan's most beautiful destinations. Discover the beauty, culture, and adventure that awaits.
              </p>
              <div className="flex gap-4">
                {socialLinks.map(({ icon: Icon, href, label }) => (
                  <motion.a
                    key={label}
                    href={href}
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label={label}
                    whileHover={{ y: -3 }}
                    className="w-10 h-10 rounded-full bg-white/10 flex items-center justify-center hover:bg-white/20 transition-colors group"
                  >
                    <Icon className="w-5 h-5 text-white group-hover:text-primary transition-colors" />
                  </motion.a>
                ))}
              </div>
            </motion.div>

            {/* Quick Links */}
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: 0.1 }}
              className="text-left"
            >
              <h4 className="text-white font-semibold text-lg mb-6">Quick Links</h4>
              <ul className="space-y-4">
                {quickLinks.map(({ to, label }) => (
                  <li key={label}>
                    <Link
                      to={to}
                      className="text-white/80 hover:text-primary transition-colors inline-flex items-center gap-1 group"
                    >
                      <span>{label}</span>
                      <ExternalLink className="w-4 h-4 opacity-0 -translate-x-2 group-hover:opacity-100 group-hover:translate-x-0 transition-all" />
                    </Link>
                  </li>
                ))}
              </ul>
            </motion.div>

            {/* Contact Info */}
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: 0.2 }}
              className="text-left"
            >
              <h4 className="text-white font-semibold text-lg mb-6">Contact Us</h4>
              <ul className="space-y-4">
                {contactInfo.map(({ icon: Icon, text }) => (
                  <li key={text} className="flex items-center gap-3 text-white/80 group">
                    <Icon className="w-5 h-5 text-primary group-hover:text-white transition-colors" />
                    <span className="group-hover:text-white transition-colors">{text}</span>
                  </li>
                ))}
              </ul>
            </motion.div>

            {/* Newsletter */}
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: 0.3 }}
              className="text-left"
            >
              <h4 className="text-white font-semibold text-lg mb-6">Stay Updated</h4>
              <p className="text-white/80 mb-4">
                Subscribe to get the latest travel tips and destinations
              </p>
              <form className="space-y-3">
                <div className="relative">
                  <input
                    type="email"
                    placeholder="Enter your email"
                    aria-label="Email for newsletter"
                    className="w-full px-4 py-2 rounded-lg bg-white/10 text-white placeholder:text-white/60 focus:outline-none focus:ring-2 focus:ring-primary focus:bg-white/20 transition-all"
                  />
                </div>
                <button 
                  type="submit"
                  className="w-full px-4 py-2 rounded-lg bg-primary hover:bg-primary-dark text-white transition-colors focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary focus:ring-offset-text-dark"
                >
                  Subscribe
                </button>
              </form>
            </motion.div>
          </div>
        </div>

        {/* Bottom Footer */}
        <div className="border-t border-white/10 py-8">
          <div className="flex flex-col md:flex-row justify-between items-center gap-4 text-white/60 text-sm">
            <p>&copy; {currentYear} Travl.pk. All rights reserved.</p>
            <div className="flex gap-6">
              {legalLinks.map(({ to, label }) => (
                <Link 
                  key={label}
                  to={to} 
                  className="hover:text-white transition-colors"
                >
                  {label}
                </Link>
              ))}
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;