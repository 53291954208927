import { useQuery } from "@tanstack/react-query";
import { motion } from "framer-motion";
import { useState } from "react";
import { supabase } from "@/integrations/supabase/client";
import { BlogsList } from "@/components/blog/BlogsList";
import { BlogsHeader } from "@/components/blog/BlogsHeader";
import { BlogsSearch } from "@/components/blog/BlogsSearch";
import BlogsListSkeleton from "@/components/blog/BlogsListSkeleton";
import { Blog } from "@/types/blog";

const Blogs = () => {
  const [searchQuery, setSearchQuery] = useState("");

  const { data: blogs, isLoading } = useQuery({
    queryKey: ["blogs"],
    queryFn: async () => {
      const { data, error } = await supabase
        .from("blogs")
        .select(`
          *,
          author:authors(name, avatar_url)
        `)
        .eq("status", "published")
        .order("created_at", { ascending: false });

      if (error) throw error;
      return data as Blog[];
    },
  });

  const filteredBlogs = blogs?.filter(blog => 
    blog.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
    blog.excerpt?.toLowerCase().includes(searchQuery.toLowerCase())
  );

  if (isLoading) {
    return (
      <div className="min-h-screen bg-gradient-to-br from-primary/5 to-secondary/5 pt-32 pb-12">
        <div className="container mx-auto px-4">
          <BlogsHeader />
          <BlogsListSkeleton />
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-br from-primary/5 to-secondary/5 pt-32 pb-12">
      <div className="container mx-auto px-4">
        <BlogsHeader />
        <BlogsSearch searchQuery={searchQuery} setSearchQuery={setSearchQuery} />
        <BlogsList blogs={filteredBlogs || []} />
      </div>
    </div>
  );
};

export default Blogs;