import { Routes, Route } from "react-router-dom";
import Hotels from "@/pages/Hotels";
import SingleHotel from "@/pages/SingleHotel";
import Resorts from "@/pages/Resorts";
import SingleResort from "@/pages/SingleResort";
import Restaurants from "@/pages/Restaurants";
import SingleRestaurant from "@/pages/SingleRestaurant";
import Index from "@/pages/Index";
import Categories from "@/pages/Categories";
import SingleDestination from "@/pages/SingleDestination";
import Blogs from "@/pages/Blogs";
import SingleBlog from "@/pages/SingleBlog";
import Destinations from "@/pages/Destinations";

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Index />} />
      <Route path="/categories" element={<Categories />} />
      <Route path="/destinations" element={<Destinations />} />
      <Route path="/destination/:slug" element={<SingleDestination />} />
      <Route path="/blog" element={<Blogs />} />
      <Route path="/blog/:slug" element={<SingleBlog />} />
      <Route path="/hotels" element={<Hotels />} />
      <Route path="/hotels/:slug" element={<SingleHotel />} />
      <Route path="/resorts" element={<Resorts />} />
      <Route path="/resorts/:slug" element={<SingleResort />} />
      <Route path="/restaurants" element={<Restaurants />} />
      <Route path="/restaurants/:slug" element={<SingleRestaurant />} />
    </Routes>
  );
};

export default AppRoutes;