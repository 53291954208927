import { useState, useEffect } from "react";
import { Button } from "@/components/ui/button";
import { motion, useScroll, useTransform } from "framer-motion";
import { Search } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { Input } from "@/components/ui/input";
import { cn } from "@/lib/utils";

const Hero = () => {
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState("");
  const { scrollY } = useScroll();
  const opacity = useTransform(scrollY, [0, 300], [1, 0]);
  const scale = useTransform(scrollY, [0, 300], [1, 0.8]);

  const handleSearch = (e: React.FormEvent) => {
    e.preventDefault();
    if (searchQuery.trim()) {
      navigate(`/destinations?search=${encodeURIComponent(searchQuery)}`);
    }
  };

  return (
    <div className="relative min-h-screen flex items-center justify-center overflow-hidden">
      {/* Background with parallax effect */}
      <motion.div 
        className="absolute inset-0 z-0"
        style={{ scale, opacity }}
      >
        <img
          src="https://images.unsplash.com/photo-1501854140801-50d01698950b"
          alt="Pakistan Landscape"
          className="w-full h-full object-cover"
        />
        <div className="absolute inset-0 bg-gradient-to-b from-black/60 via-black/50 to-black/70" />
      </motion.div>
      
      <div className="container mx-auto px-4 z-10">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="text-center max-w-4xl mx-auto"
        >
          <motion.span 
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.2 }}
            className="inline-block px-6 py-2 mb-6 rounded-full bg-primary/10 text-primary border border-primary/20 backdrop-blur-sm font-medium"
          >
            Discover Pakistan's Hidden Gems
          </motion.span>
          
          <h1 className="text-4xl sm:text-5xl md:text-7xl font-serif font-bold text-white mb-6 leading-tight">
            Your Journey
            <span className="text-primary block mt-2">Begins Here</span>
          </h1>
          
          <p className="text-lg md:text-xl text-white/90 mb-12 max-w-2xl mx-auto leading-relaxed">
            Let our AI guide you to personalized adventures across Pakistan's most breathtaking destinations
          </p>
          
          {/* Simplified Search Form */}
          <form onSubmit={handleSearch} className="max-w-2xl mx-auto">
            <div className="bg-white/10 backdrop-blur-md p-3 rounded-2xl border border-white/20 mb-8">
              <div className="relative">
                <Input
                  type="text"
                  placeholder="Where would you like to go?"
                  className="w-full bg-white/10 border-none text-white placeholder:text-white/70 pl-12 pr-4 py-6 rounded-xl focus:ring-2 focus:ring-primary/50 transition-all duration-200"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
                <Search className="absolute left-4 top-1/2 -translate-y-1/2 w-5 h-5 text-white/70" />
                <Button
                  type="submit"
                  className="absolute right-2 top-1/2 -translate-y-1/2 bg-primary hover:bg-primary-dark text-white font-medium px-6 py-2 rounded-lg hover:scale-105 transition-all duration-200 shadow-lg hover:shadow-primary/30"
                >
                  Explore
                </Button>
              </div>
            </div>
          </form>
          
          {/* Stats */}
          <div className="flex flex-wrap justify-center gap-8 text-white/90">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.4 }}
              className="text-center"
            >
              <div className="text-3xl font-bold mb-1">500+</div>
              <div className="text-sm opacity-80">Destinations</div>
            </motion.div>
            <div className="hidden sm:block w-px bg-white/20 h-12 my-auto" />
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.5 }}
              className="text-center"
            >
              <div className="text-3xl font-bold mb-1">50k+</div>
              <div className="text-sm opacity-80">Happy Travelers</div>
            </motion.div>
            <div className="hidden sm:block w-px bg-white/20 h-12 my-auto" />
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.6 }}
              className="text-center"
            >
              <div className="text-3xl font-bold mb-1">98%</div>
              <div className="text-sm opacity-80">Satisfaction</div>
            </motion.div>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default Hero;