import { useQuery } from "@tanstack/react-query";
import { motion, AnimatePresence } from "framer-motion";
import { supabase } from "@/integrations/supabase/client";
import { useNavigate, useParams } from "react-router-dom";
import { ArrowLeft, Share2 } from "lucide-react";
import { Button } from "@/components/ui/button";
import { Helmet } from "react-helmet-async";
import { useToast } from "@/hooks/use-toast";
import { Blog, SocialLinks } from "@/types/blog";
import { BlogHeader } from "@/components/blog/BlogHeader";
import { BlogContent } from "@/components/blog/BlogContent";
import BlogSkeleton from "@/components/blog/BlogSkeleton";

const SingleBlog = () => {
  const navigate = useNavigate();
  const { slug } = useParams();
  const { toast } = useToast();

  const { data: blog, isLoading, error } = useQuery({
    queryKey: ["blog", slug],
    queryFn: async () => {
      const { data, error } = await supabase
        .from("blogs")
        .select(`
          *,
          author:authors(*)
        `)
        .eq("slug", slug)
        .maybeSingle();

      if (error) throw error;
      return data as Blog;
    },
  });

  const handleShare = async () => {
    if (!blog) return;

    try {
      const shareData = {
        url: window.location.href,
      };

      if (navigator.share) {
        await navigator.share(shareData);
      } else {
        await navigator.clipboard.writeText(window.location.href);
        toast({
          title: "Success",
          description: "The blog link has been copied to your clipboard.",
        });
      }
    } catch (error) {
      console.error("Error sharing:", error);
    }
  };

  if (error) {
    return (
      <div className="min-h-screen flex flex-col items-center justify-center bg-gradient-to-br from-primary/5 to-secondary/5">
        <h1 className="text-3xl font-serif font-bold mb-4">Error Loading Blog</h1>
        <p className="text-gray-600 mb-6">Sorry, something went wrong while loading the blog.</p>
        <Button onClick={() => navigate("/blog")}>Return to Blogs</Button>
      </div>
    );
  }

  if (isLoading || !blog) {
    return <BlogSkeleton />;
  }

  const socialLinks = blog.author?.social_links as SocialLinks | null;
  const viewsCount = blog.views_count ?? 0;

  return (
    <>
      <Helmet>
        <title>{blog?.meta_title || blog?.title} | Travl.pk</title>
        <meta name="description" content={blog?.meta_description || blog?.excerpt || ''} />
        <meta property="og:title" content={blog?.meta_title || blog?.title} />
        <meta property="og:description" content={blog?.meta_description || blog?.excerpt || ''} />
        {blog?.featured_image && <meta property="og:image" content={blog.featured_image} />}
        <link rel="canonical" href={`https://travl.pk/blog/${blog?.slug}`} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={blog?.meta_title || blog?.title} />
        <meta name="twitter:description" content={blog?.meta_description || blog?.excerpt || ''} />
        {blog?.featured_image && <meta name="twitter:image" content={blog.featured_image} />}
      </Helmet>

      <AnimatePresence mode="wait">
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="min-h-screen bg-gradient-to-br from-primary/5 to-secondary/5 pt-32 pb-12"
        >
          <div className="container mx-auto px-4">
            <Button
              variant="ghost"
              className="mb-8 hover:bg-white/20 group"
              onClick={() => navigate("/blog")}
            >
              <ArrowLeft className="mr-2 group-hover:-translate-x-1 transition-transform" />
              Back to Blogs
            </Button>

            <motion.article
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              className="max-w-4xl mx-auto bg-white/80 backdrop-blur-sm rounded-2xl shadow-xl overflow-hidden"
            >
              {blog.featured_image && (
                <div className="aspect-[21/9] relative">
                  <img
                    src={blog.featured_image}
                    alt={blog.title}
                    className="w-full h-full object-cover"
                    loading="eager"
                  />
                  <div className="absolute inset-0 bg-gradient-to-t from-black/50 to-transparent" />
                </div>
              )}

              <div className="p-8 md:p-12">
                <div className="flex justify-between items-start mb-6">
                  <BlogHeader blog={blog} viewsCount={viewsCount} />
                  <Button
                    variant="ghost"
                    size="icon"
                    className="hover:bg-secondary/50"
                    onClick={handleShare}
                  >
                    <Share2 className="w-5 h-5" />
                  </Button>
                </div>
                
                <BlogContent blog={blog} socialLinks={socialLinks} />
              </div>
            </motion.article>
          </div>
        </motion.div>
      </AnimatePresence>
    </>
  );
};

export default SingleBlog;