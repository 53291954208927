import { Skeleton } from "@/components/ui/skeleton";

const BlogsListSkeleton = () => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
      {[1, 2, 3, 4, 5, 6].map((index) => (
        <article key={index} className="bg-white/80 backdrop-blur-sm rounded-2xl overflow-hidden shadow-lg hover:shadow-xl transition-all duration-300 h-full flex flex-col">
          <Skeleton className="aspect-[16/9] w-full" />
          <div className="p-6 flex-grow flex flex-col">
            <div className="flex items-center gap-4 text-sm text-gray-600 mb-4">
              <div className="flex items-center gap-1">
                <Skeleton className="w-4 h-4" />
                <Skeleton className="w-24 h-3" />
              </div>
              <div className="flex items-center gap-1">
                <Skeleton className="w-4 h-4" />
                <Skeleton className="w-20 h-3" />
              </div>
            </div>
            <Skeleton className="h-6 w-3/4 mb-3" />
            <Skeleton className="h-4 w-full mb-2" />
            <Skeleton className="h-4 w-5/6 mb-6" />
            <div className="flex items-center gap-3 mt-auto">
              <Skeleton className="w-8 h-8 rounded-full" />
              <Skeleton className="w-24 h-3" />
            </div>
          </div>
        </article>
      ))}
    </div>
  );
};

export default BlogsListSkeleton;