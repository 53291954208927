import { motion } from "framer-motion";
import { Brain, Map, Calendar, Star, Shield, Sparkles } from "lucide-react";

const features = [
  {
    icon: Brain,
    title: "Smart Recommendations",
    description: "Our AI analyzes your preferences to suggest perfect destinations",
    color: "bg-blue-500/10 text-blue-500",
  },
  {
    icon: Map,
    title: "Custom Itineraries",
    description: "Get personalized travel plans tailored to your interests",
    color: "bg-green-500/10 text-green-500",
  },
  {
    icon: Calendar,
    title: "Optimal Timing",
    description: "Know the best time to visit each destination",
    color: "bg-purple-500/10 text-purple-500",
  },
  {
    icon: Star,
    title: "Local Insights",
    description: "Access insider tips and hidden gems from locals",
    color: "bg-yellow-500/10 text-yellow-500",
  },
  {
    icon: Shield,
    title: "Safe Travel",
    description: "Real-time safety updates and travel advisories",
    color: "bg-red-500/10 text-red-500",
  },
  {
    icon: Sparkles,
    title: "Personalization",
    description: "Travel experiences that match your unique style",
    color: "bg-indigo-500/10 text-indigo-500",
  },
];

const AIFeatures = () => {
  return (
    <section className="py-24 bg-gradient-to-b from-secondary/30 to-white">
      <div className="container mx-auto px-4">
        <div className="text-center mb-16">
          <motion.span
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
            className="text-primary font-medium px-4 py-2 rounded-full bg-primary/10 inline-block mb-4"
          >
            Powered by AI
          </motion.span>
          <motion.h2
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="text-3xl sm:text-4xl md:text-5xl font-serif font-bold mt-2 mb-6"
          >
            Travel Smarter, Not Harder
          </motion.h2>
          <motion.p
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
            className="text-gray-600 max-w-2xl mx-auto px-4"
          >
            Our AI-powered platform revolutionizes how you explore Pakistan, making every journey uniquely yours
          </motion.p>
        </div>
        
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 md:gap-8">
          {features.map((feature, index) => (
            <motion.div
              key={feature.title}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: index * 0.1 }}
              className="bg-white rounded-2xl p-6 md:p-8 shadow-lg shadow-gray-100/50 hover:shadow-xl transition-all duration-300 hover:-translate-y-1"
            >
              <div className={`inline-block p-4 rounded-xl ${feature.color} mb-6`}>
                <feature.icon className="w-6 h-6" />
              </div>
              <h3 className="text-xl font-semibold mb-4">{feature.title}</h3>
              <p className="text-gray-600">{feature.description}</p>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default AIFeatures;