import { motion } from "framer-motion";
import { useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import { toast } from "sonner";
import { Helmet } from "react-helmet-async";
import { Button } from "@/components/ui/button";
import { Share2, Heart, ArrowLeft, MapPin, Calendar, Users, Star, Tag, Wifi, Coffee, Utensils } from "lucide-react";

const SingleHotel = () => {
  const navigate = useNavigate();
  const { slug } = useParams();
  const [selectedImage, setSelectedImage] = useState(0);

  const { data: hotel, isLoading } = useQuery({
    queryKey: ["hotel", slug],
    queryFn: async () => {
      // First get the hotel details
      const { data: hotelData, error: hotelError } = await supabase
        .from("hotels")
        .select(`
          *,
          category:categories(name, slug)
        `)
        .eq("slug", slug)
        .maybeSingle();

      if (hotelError) throw hotelError;
      if (!hotelData) throw new Error("Hotel not found");

      // Then get the property images
      const { data: imagesData, error: imagesError } = await supabase
        .from("property_images")
        .select("*")
        .eq("property_type", "hotel")
        .eq("property_id", hotelData.id)
        .order("display_order", { ascending: true });

      if (imagesError) throw imagesError;

      return {
        ...hotelData,
        images: imagesData || []
      };
    },
  });

  const handleShare = async () => {
    try {
      await navigator.share({
        title: hotel?.title,
        text: hotel?.description,
        url: window.location.href,
      });
    } catch (error) {
      toast.error("Sharing failed. Try copying the URL manually.");
    }
  };

  if (isLoading) {
    return (
      <div className="min-h-screen bg-gradient-to-br from-primary/5 to-secondary/5 pt-16 flex items-center justify-center">
        <div className="w-16 h-16 border-4 border-primary border-t-transparent rounded-full animate-spin" />
      </div>
    );
  }

  if (!hotel) {
    return (
      <div className="min-h-screen flex flex-col items-center justify-center bg-gradient-to-br from-primary/5 to-secondary/5">
        <h1 className="text-3xl font-serif font-bold mb-4">Hotel Not Found</h1>
        <Button onClick={() => navigate("/hotels")}>
          View All Hotels
        </Button>
      </div>
    );
  }

  const galleryImages = hotel.images && hotel.images.length > 0
    ? hotel.images.map(img => img.image_url)
    : hotel.featured_image_url
    ? [hotel.featured_image_url]
    : [];

  return (
    <>
      <Helmet>
        <title>{hotel?.title} | Hotels</title>
        <meta name="description" content={hotel?.description || ""} />
      </Helmet>

      <motion.div 
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className="min-h-screen bg-gradient-to-br from-primary/5 to-secondary/5"
      >
        <div className="relative h-[85vh] overflow-hidden">
          <motion.div 
            initial={{ scale: 1.1 }}
            animate={{ scale: 1 }}
            transition={{ duration: 0.8, ease: "easeOut" }}
            className="absolute inset-0"
          >
            {/* Gallery */}
            <div className="relative h-full">
              <img
                src={galleryImages[selectedImage]}
                alt={hotel.title}
                className="w-full h-full object-cover"
              />
              <div className="absolute inset-0 bg-gradient-to-b from-black/40 via-black/20 to-black/60" />
            </div>

            {/* Thumbnails */}
            {galleryImages.length > 1 && (
              <div className="absolute bottom-6 left-6 right-6">
                <div className="container mx-auto">
                  <div className="flex gap-2 overflow-x-auto pb-2 scrollbar-hide">
                    {galleryImages.map((image, index) => (
                      <button
                        key={index}
                        onClick={() => setSelectedImage(index)}
                        className={`flex-shrink-0 w-20 h-20 rounded-lg overflow-hidden border-2 transition-all duration-300 ${
                          selectedImage === index 
                            ? "border-primary ring-2 ring-primary/20" 
                            : "border-white/50 hover:border-white"
                        }`}
                      >
                        <img 
                          src={image} 
                          alt="" 
                          className="w-full h-full object-cover"
                        />
                      </button>
                    ))}
                  </div>
                </div>
              </div>
            )}
          </motion.div>

          {/* Header Actions */}
          <div className="absolute top-6 left-6 right-6 flex justify-between items-center">
            <Button 
              variant="ghost" 
              className="text-white hover:bg-white/20 backdrop-blur-sm"
              onClick={() => navigate(-1)}
            >
              <ArrowLeft className="mr-2" /> Back
            </Button>
            <div className="flex gap-2">
              <Button 
                variant="ghost" 
                className="text-white hover:bg-white/20 backdrop-blur-sm"
                onClick={() => toast.success("Feature coming soon!")}
              >
                <Heart className="mr-2" /> Save
              </Button>
              <Button 
                variant="ghost" 
                className="text-white hover:bg-white/20 backdrop-blur-sm"
                onClick={handleShare}
              >
                <Share2 className="mr-2" /> Share
              </Button>
            </div>
          </div>

          {/* Hotel Info */}
          <motion.div 
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.3 }}
            className="absolute bottom-32 left-0 right-0 text-left px-4 md:px-8"
          >
            <div className="container mx-auto max-w-7xl">
              <h1 className="text-5xl md:text-6xl font-serif font-bold mb-4 text-white text-shadow-lg">
                {hotel?.title}
              </h1>
              {hotel?.description && (
                <p className="text-lg md:text-xl max-w-3xl text-white/90 text-shadow">
                  {hotel?.description}
                </p>
              )}
            </div>
          </motion.div>
        </div>

        <div className="container mx-auto px-4 -mt-20 relative z-10 pb-20">
          <motion.div 
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.5 }}
            className="grid grid-cols-1 lg:grid-cols-3 gap-8"
          >
            {/* Main Content */}
            <div className="lg:col-span-2">
              <div className="bg-white/80 backdrop-blur-sm rounded-2xl p-8 shadow-sm mb-8 hover:bg-white transition-colors duration-300">
                <div className="flex items-center gap-2 text-text/80 mb-4">
                  <MapPin className="w-5 h-5" />
                  <span>{hotel.location}</span>
                </div>

                <div className="flex flex-wrap items-center gap-6 mb-8">
                  {hotel.rating && (
                    <div className="flex items-center gap-1">
                      <Star className="w-5 h-5 fill-yellow-400 text-yellow-400" />
                      <span className="font-medium">{hotel.rating}</span>
                    </div>
                  )}
                  {hotel.category && (
                    <Button
                      variant="ghost"
                      className="text-primary hover:text-primary-dark"
                      onClick={() => navigate(`/categories/${hotel.category.slug}`)}
                    >
                      {hotel.category.name}
                    </Button>
                  )}
                </div>
                
                <div className="grid grid-cols-1 md:grid-cols-3 gap-6 my-8">
                  {hotel.check_in_time && (
                    <div className="p-4 bg-primary/5 rounded-xl">
                      <Calendar className="w-6 h-6 text-primary mb-2" />
                      <h3 className="font-medium mb-1">Check-in Time</h3>
                      <p className="text-sm text-text/70">{hotel.check_in_time}</p>
                    </div>
                  )}
                  {hotel.check_out_time && (
                    <div className="p-4 bg-primary/5 rounded-xl">
                      <Calendar className="w-6 h-6 text-primary mb-2" />
                      <h3 className="font-medium mb-1">Check-out Time</h3>
                      <p className="text-sm text-text/70">{hotel.check_out_time}</p>
                    </div>
                  )}
                  {hotel.price_range && (
                    <div className="p-4 bg-primary/5 rounded-xl">
                      <Users className="w-6 h-6 text-primary mb-2" />
                      <h3 className="font-medium mb-1">Price Range</h3>
                      <p className="text-sm text-text/70">{hotel.price_range}</p>
                    </div>
                  )}
                </div>

                {hotel.content && (
                  <>
                    <h2 className="text-2xl font-serif font-semibold mb-4">About this Hotel</h2>
                    <div 
                      className="text-text/80 space-y-4"
                      dangerouslySetInnerHTML={{ __html: hotel.content }}
                    />
                  </>
                )}

                {hotel.amenities && hotel.amenities.length > 0 && (
                  <div className="mt-8">
                    <h3 className="text-lg font-medium mb-4">Amenities</h3>
                    <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
                      {hotel.amenities.map((amenity) => (
                        <div key={amenity} className="flex items-center gap-2">
                          {amenity === 'wifi' && <Wifi className="w-4 h-4" />}
                          {amenity === 'restaurant' && <Utensils className="w-4 h-4" />}
                          {amenity === 'coffee' && <Coffee className="w-4 h-4" />}
                          <span className="text-sm">{amenity}</span>
                        </div>
                      ))}
                    </div>
                  </div>
                )}

                {hotel.tags && hotel.tags.length > 0 && (
                  <div className="mt-8">
                    <h3 className="text-lg font-medium mb-3">Tags</h3>
                    <div className="flex flex-wrap gap-2">
                      {hotel.tags.map((tag) => (
                        <span
                          key={tag}
                          className="inline-flex items-center gap-1 px-3 py-1 text-sm rounded-full bg-secondary text-text font-medium"
                        >
                          <Tag className="w-4 h-4" />
                          {tag}
                        </span>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </div>

            {/* Sidebar */}
            <div className="lg:col-span-1">
              <div className="bg-white/80 backdrop-blur-sm rounded-2xl p-6 shadow-sm sticky top-24 hover:bg-white transition-colors duration-300">
                <div className="flex items-center justify-between mb-6">
                  <div>
                    <span className="text-3xl font-bold text-primary">
                      {hotel.price_range}
                    </span>
                  </div>
                  {hotel.rating && (
                    <div className="flex items-center gap-1 bg-yellow-400/10 text-yellow-600 px-3 py-1 rounded-full">
                      <Star className="w-4 h-4 fill-current" />
                      <span className="font-medium">{hotel.rating}</span>
                    </div>
                  )}
                </div>

                <Button 
                  className="w-full bg-primary hover:bg-primary-dark text-white mb-4"
                  onClick={() => toast.success("Booking feature coming soon!")}
                >
                  Book Now
                </Button>
                <Button 
                  variant="outline" 
                  className="w-full"
                  onClick={() => toast.success("Contact form coming soon!")}
                >
                  Ask a Question
                </Button>
              </div>
            </div>
          </motion.div>
        </div>
      </motion.div>
    </>
  );
};

export default SingleHotel;