import { motion } from "framer-motion";
import { useQuery } from "@tanstack/react-query";
import { Helmet } from "react-helmet-async";
import Hero from "@/components/Hero";
import AIFeatures from "@/components/AIFeatures";
import DestinationCard from "@/components/DestinationCard";
import Testimonials from "@/components/Testimonials";
import Footer from "@/components/Footer";
import ScrollToTop from "@/components/ScrollToTop";
import { supabase } from "@/integrations/supabase/client";
import { useToast } from "@/components/ui/use-toast";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "@/components/ui/carousel";

const Index = () => {
  const { toast } = useToast();

  const { data: destinations, isLoading, error } = useQuery({
    queryKey: ["popular-destinations"],
    queryFn: async () => {
      const { data, error } = await supabase
        .from("destinations")
        .select("*")
        .eq("status", "published")
        .order("rating", { ascending: false })
        .limit(5);

      if (error) {
        toast({
          variant: "destructive",
          title: "Error fetching destinations",
          description: error.message,
        });
        throw error;
      }

      return data;
    },
  });

  return (
    <>
      <Helmet>
        <title>Discover Pakistan - Your AI-Powered Travel Companion</title>
        <meta
          name="description"
          content="Explore Pakistan's hidden gems with our AI-powered travel platform. Discover personalized adventures across breathtaking destinations."
        />
        <meta
          name="keywords"
          content="Pakistan travel, AI travel guide, tourism Pakistan, travel destinations"
        />
        <link rel="canonical" href={window.location.href} />
      </Helmet>

      <div className="min-h-screen">
        <Hero />
        <AIFeatures />
        
        <section className="py-24 bg-gradient-to-br from-primary/5 to-secondary/5">
          <div className="container mx-auto px-4">
            <div className="text-center mb-16">
              <motion.span
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                viewport={{ once: true }}
                className="text-primary font-medium px-4 py-2 rounded-full bg-primary/10 inline-block mb-4"
              >
                Popular Destinations
              </motion.span>
              <motion.h2
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                className="text-3xl md:text-4xl font-serif font-bold mt-2"
              >
                Explore Pakistan's Finest
              </motion.h2>
            </div>
            
            {isLoading ? (
              <div className="flex justify-center items-center min-h-[400px]">
                <div className="w-16 h-16 border-4 border-primary border-t-transparent rounded-full animate-spin" />
              </div>
            ) : error ? (
              <div className="text-center text-red-500">
                Failed to load destinations. Please try again later.
              </div>
            ) : (
              <Carousel
                opts={{
                  align: "start",
                  loop: true,
                }}
                className="w-full max-w-6xl mx-auto"
              >
                <CarouselContent>
                  {destinations?.map((destination, index) => (
                    <CarouselItem key={destination.id} className="md:basis-1/2 lg:basis-1/3">
                      <motion.div
                        initial={{ opacity: 0, y: 20 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        viewport={{ once: true }}
                        transition={{ delay: index * 0.2 }}
                      >
                        <DestinationCard
                          id={destination.slug}
                          image={destination.featured_image_url || ""}
                          title={destination.title}
                          description={destination.description || ""}
                          tags={destination.tags || []}
                          rating={destination.rating || 0}
                          location={destination.location || ""}
                        />
                      </motion.div>
                    </CarouselItem>
                  ))}
                </CarouselContent>
                <CarouselPrevious className="hidden md:flex" />
                <CarouselNext className="hidden md:flex" />
              </Carousel>
            )}
          </div>
        </section>
        
        <Testimonials />
        <ScrollToTop />
      </div>
    </>
  );
};

export default Index;
