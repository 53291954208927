import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { Calendar, Clock, User } from "lucide-react";
import { Blog } from "@/types/blog";

interface BlogsListProps {
  blogs: Blog[];
}

export const BlogsList = ({ blogs }: BlogsListProps) => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
      {blogs.map((blog, index) => (
        <motion.div
          key={blog.id}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.3, delay: index * 0.1 }}
        >
          <Link to={`/blog/${blog.slug}`} className="block group h-full">
            <article className="bg-white/80 backdrop-blur-sm rounded-2xl overflow-hidden shadow-lg hover:shadow-xl transition-all duration-300 h-full flex flex-col">
              <div className="aspect-[16/9] relative overflow-hidden">
                <img
                  src={blog.featured_image || "/placeholder.svg"}
                  alt={blog.title}
                  className="w-full h-full object-cover transform group-hover:scale-105 transition-transform duration-300"
                />
                <div className="absolute inset-0 bg-gradient-to-t from-black/30 to-transparent opacity-0 group-hover:opacity-100 transition-opacity" />
              </div>
              <div className="p-6 flex-grow flex flex-col">
                <div className="flex items-center gap-4 text-sm text-gray-600 mb-4">
                  <div className="flex items-center gap-1">
                    <Calendar className="w-4 h-4" />
                    <span>
                      {new Date(blog.created_at).toLocaleDateString()}
                    </span>
                  </div>
                  {blog.reading_time && (
                    <div className="flex items-center gap-1">
                      <Clock className="w-4 h-4" />
                      <span>{blog.reading_time} min read</span>
                    </div>
                  )}
                </div>
                <h2 className="text-xl font-serif font-semibold mb-3 group-hover:text-primary transition-colors line-clamp-2">
                  {blog.title}
                </h2>
                <p className="text-gray-600 mb-6 line-clamp-2">
                  {blog.excerpt}
                </p>
                <div className="flex items-center gap-3 mt-auto">
                  <div className="w-8 h-8 rounded-full overflow-hidden bg-gray-100 ring-2 ring-primary/20">
                    {blog.author?.avatar_url ? (
                      <img
                        src={blog.author.avatar_url}
                        alt={blog.author.name}
                        className="w-full h-full object-cover"
                      />
                    ) : (
                      <User className="w-full h-full p-1.5 text-gray-400" />
                    )}
                  </div>
                  <span className="text-sm font-medium text-gray-700">
                    {blog.author?.name}
                  </span>
                </div>
              </div>
            </article>
          </Link>
        </motion.div>
      ))}
    </div>
  );
};