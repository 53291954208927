import { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import { Menu, X, ChevronDown, Search } from "lucide-react";
import { cn } from "@/lib/utils";

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const [searchOpen, setSearchOpen] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 20);
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    setIsMenuOpen(false);
    setSearchOpen(false);
  }, [location]);

  const navItems = [
    { to: "/destinations", label: "Destinations" },
    { to: "/categories", label: "Categories" },
    { to: "/blog", label: "Travel Stories" },
    { to: "/contact", label: "Contact" }
  ];

  return (
    <header 
      className={cn(
        "fixed top-0 left-0 right-0 z-50 transition-all duration-500",
        scrolled 
          ? "bg-white/95 backdrop-blur-lg shadow-lg" 
          : "bg-gradient-to-b from-black/50 to-transparent"
      )}
      role="banner"
    >
      <div className="container mx-auto px-4">
        <nav 
          className="flex items-center justify-between h-20"
          role="navigation"
          aria-label="Main navigation"
        >
          {/* Logo */}
          <Link 
            to="/" 
            className="relative group z-50"
            aria-label="Go to homepage"
          >
            <motion.span 
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              className="text-2xl md:text-3xl font-serif font-bold"
            >
              <span className={cn(
                "transition-colors duration-300",
                scrolled ? "text-primary" : "text-primary"
              )}>Travl</span>
              <span className={cn(
                "transition-colors duration-300",
                scrolled ? "text-text" : "text-white"
              )}>.pk</span>
            </motion.span>
          </Link>

          {/* Desktop Navigation */}
          <div className="hidden md:flex items-center space-x-8">
            {navItems.map((item) => (
              <Link 
                key={item.to}
                to={item.to} 
                className={cn(
                  "relative group py-2 font-medium transition-colors duration-300 text-left",
                  scrolled ? "text-text hover:text-primary" : "text-white hover:text-primary",
                  location.pathname === item.to && "text-primary"
                )}
                aria-current={location.pathname === item.to ? "page" : undefined}
              >
                <span className="flex items-center gap-1">
                  {item.label}
                  <ChevronDown className="w-4 h-4 transition-transform group-hover:rotate-180" />
                </span>
                <span className="absolute bottom-0 left-0 w-0 h-0.5 bg-primary transition-all duration-300 group-hover:w-full" />
              </Link>
            ))}
            <button
              onClick={() => setSearchOpen(!searchOpen)}
              className={cn(
                "p-2 rounded-full transition-colors",
                scrolled ? "hover:bg-gray-100" : "hover:bg-white/10"
              )}
              aria-label="Toggle search"
            >
              <Search className={cn(
                "w-5 h-5",
                scrolled ? "text-text" : "text-white"
              )} />
            </button>
          </div>

          {/* Mobile Menu Button */}
          <motion.button
            className="md:hidden relative z-50 p-2 rounded-full transition-colors hover:bg-white/10"
            onClick={() => setIsMenuOpen(!isMenuOpen)}
            whileTap={{ scale: 0.95 }}
            aria-expanded={isMenuOpen}
            aria-controls="mobile-menu"
            aria-label="Toggle menu"
          >
            <AnimatePresence mode="wait">
              {isMenuOpen ? (
                <motion.div
                  key="close"
                  initial={{ rotate: -90, opacity: 0 }}
                  animate={{ rotate: 0, opacity: 1 }}
                  exit={{ rotate: 90, opacity: 0 }}
                  transition={{ duration: 0.2 }}
                >
                  <X className={cn(
                    "w-6 h-6",
                    scrolled ? "text-text" : "text-white"
                  )} />
                </motion.div>
              ) : (
                <motion.div
                  key="menu"
                  initial={{ rotate: 90, opacity: 0 }}
                  animate={{ rotate: 0, opacity: 1 }}
                  exit={{ rotate: -90, opacity: 0 }}
                  transition={{ duration: 0.2 }}
                >
                  <Menu className={cn(
                    "w-6 h-6",
                    scrolled ? "text-text" : "text-white"
                  )} />
                </motion.div>
              )}
            </AnimatePresence>
          </motion.button>
        </nav>
      </div>

      {/* Mobile Menu */}
      <AnimatePresence>
        {isMenuOpen && (
          <motion.div
            id="mobile-menu"
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: "auto" }}
            exit={{ opacity: 0, height: 0 }}
            transition={{ duration: 0.3 }}
            className="absolute top-full left-0 right-0 bg-white/95 backdrop-blur-lg border-b border-gray-200/50 shadow-lg md:hidden"
          >
            <div className="container mx-auto px-4 py-6">
              <nav className="flex flex-col space-y-4">
                {navItems.map((item) => (
                  <Link 
                    key={item.to}
                    to={item.to} 
                    className={cn(
                      "text-text hover:text-primary transition-colors text-lg font-medium py-2 px-4 rounded-lg hover:bg-gray-50 text-left",
                      location.pathname === item.to && "text-primary bg-gray-50"
                    )}
                    onClick={() => setIsMenuOpen(false)}
                    aria-current={location.pathname === item.to ? "page" : undefined}
                  >
                    {item.label}
                  </Link>
                ))}
              </nav>
            </div>
          </motion.div>
        )}
      </AnimatePresence>

      {/* Search Overlay */}
      <AnimatePresence>
        {searchOpen && (
          <motion.div
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            className="absolute top-full left-0 right-0 bg-white/95 backdrop-blur-lg shadow-lg p-4"
          >
            <div className="container mx-auto">
              <div className="relative">
                <input
                  type="search"
                  placeholder="Search destinations..."
                  className="w-full px-4 py-2 pl-10 rounded-lg border border-gray-200 focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent"
                />
                <Search className="absolute left-3 top-1/2 -translate-y-1/2 w-5 h-5 text-gray-400" />
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </header>
  );
};

export default Header;