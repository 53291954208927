import { BrowserRouter as Router } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { HelmetProvider } from "react-helmet-async";
import { LazyMotion, domAnimation, AnimatePresence } from "framer-motion";
import Header from "@/components/Header";
import Footer from "@/components/Footer";
import ScrollToTop from "@/components/ScrollToTop";
import { Toaster } from "sonner";
import AppRoutes from "@/routes";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        <HelmetProvider>
          <LazyMotion features={domAnimation}>
            <AnimatePresence mode="wait">
              <div className="flex flex-col min-h-screen">
                <Header />
                <main className="flex-grow">
                  <AppRoutes />
                </main>
                <Footer />
                <ScrollToTop />
                <Toaster />
              </div>
            </AnimatePresence>
          </LazyMotion>
        </HelmetProvider>
      </Router>
    </QueryClientProvider>
  );
};

export default App;