import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import { Helmet } from "react-helmet-async";

const SingleRestaurant = () => {
  const { slug } = useParams();

  const { data: restaurant, isLoading } = useQuery({
    queryKey: ["restaurant", slug],
    queryFn: async () => {
      const { data, error } = await supabase
        .from("restaurants")
        .select(`
          *,
          category:categories(name, slug)
        `)
        .eq("slug", slug)
        .eq("status", "published")
        .single();

      if (error) throw error;
      return data;
    },
  });

  if (isLoading) {
    return (
      <div className="flex justify-center items-center min-h-[400px]">
        <div className="w-16 h-16 border-4 border-primary border-t-transparent rounded-full animate-spin" />
      </div>
    );
  }

  if (!restaurant) {
    return (
      <div className="container mx-auto px-4 py-12">
        <h1 className="text-2xl font-bold">Restaurant not found</h1>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-br from-primary/5 to-secondary/5 pt-20">
      <Helmet>
        <title>{restaurant.title} | Discover Pakistan</title>
        <meta name="description" content={restaurant.meta_description || restaurant.description} />
      </Helmet>

      <div className="container mx-auto px-4 py-12">
        <h1 className="text-4xl font-serif font-bold mb-6">{restaurant.title}</h1>
        <div className="prose max-w-none">{restaurant.content}</div>
      </div>
    </div>
  );
};

export default SingleRestaurant;