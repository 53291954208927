import { useState } from "react";
import { motion } from "framer-motion";
import { useSearchParams } from "react-router-dom";
import { Filter, MapPin } from "lucide-react";
import { useQuery } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import HotelCard from "@/components/hotel/HotelCard";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Helmet } from "react-helmet-async";

const Hotels = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [priceRange, setPriceRange] = useState("all");

  const searchQuery = searchParams.get("search") || "";

  const { data: hotels, isLoading } = useQuery({
    queryKey: ["hotels", searchQuery, priceRange],
    queryFn: async () => {
      let query = supabase
        .from("hotels")
        .select(`
          *,
          category:categories(name, slug)
        `)
        .eq('status', 'published');

      if (priceRange !== "all") {
        query = query.eq('price_range', priceRange);
      }

      if (searchQuery) {
        query = query.or(`title.ilike.%${searchQuery}%,description.ilike.%${searchQuery}%`);
      }

      const { data, error } = await query;
      if (error) throw error;
      return data;
    },
  });

  return (
    <div className="min-h-screen bg-gradient-to-br from-primary/5 to-secondary/5 pt-20">
      <Helmet>
        <title>Hotels in Pakistan | Discover Pakistan</title>
        <meta
          name="description"
          content="Find and book the best hotels across Pakistan. From luxury stays to budget-friendly options, discover your perfect accommodation."
        />
      </Helmet>

      <div className="container mx-auto px-4 py-12">
        {/* Hero Section */}
        <div className="text-center mb-16">
          <motion.h1
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="text-4xl md:text-5xl font-serif font-bold mb-6 text-text"
          >
            Find Your Perfect Stay
          </motion.h1>
          <p className="text-lg text-text/80 max-w-2xl mx-auto">
            Discover comfortable and luxurious hotels across Pakistan's most beautiful destinations
          </p>
        </div>

        {/* Filters */}
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.2 }}
          className="bg-white/80 backdrop-blur-sm rounded-xl shadow-sm p-6 mb-8"
        >
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <label className="text-sm font-medium mb-2 block">Price Range</label>
              <Select
                value={priceRange}
                onValueChange={setPriceRange}
              >
                <SelectTrigger>
                  <SelectValue placeholder="Select price range" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="all">All Prices</SelectItem>
                  <SelectItem value="Budget">Budget</SelectItem>
                  <SelectItem value="Mid-Range">Mid-Range</SelectItem>
                  <SelectItem value="Luxury">Luxury</SelectItem>
                </SelectContent>
              </Select>
            </div>
            <div>
              <label className="text-sm font-medium mb-2 block">Search</label>
              <div className="relative">
                <Input
                  type="text"
                  placeholder="Search hotels..."
                  className="pl-10"
                  value={searchQuery}
                  onChange={(e) => setSearchParams({ search: e.target.value })}
                />
                <MapPin className="absolute left-3 top-1/2 -translate-y-1/2 w-4 h-4 text-gray-400" />
              </div>
            </div>
          </div>
        </motion.div>

        {/* Results */}
        {isLoading ? (
          <div className="flex justify-center items-center min-h-[400px]">
            <div className="w-16 h-16 border-4 border-primary border-t-transparent rounded-full animate-spin" />
          </div>
        ) : hotels && hotels.length > 0 ? (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {hotels.map((hotel, index) => (
              <motion.div
                key={hotel.id}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.1 }}
              >
                <HotelCard
                  id={hotel.slug}
                  image={hotel.featured_image_url || ""}
                  title={hotel.title}
                  description={hotel.description || ""}
                  tags={hotel.tags || []}
                  rating={hotel.rating || 0}
                  location={hotel.location || ""}
                  amenities={hotel.amenities || []}
                  priceRange={hotel.price_range}
                />
              </motion.div>
            ))}
          </div>
        ) : (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            className="text-center py-12"
          >
            <h3 className="text-2xl font-serif font-semibold mb-4">No hotels found</h3>
            <p className="text-text/70">Try adjusting your search criteria</p>
          </motion.div>
        )}
      </div>
    </div>
  );
};

export default Hotels;