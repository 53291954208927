import { Clock, Calendar, Eye, User } from "lucide-react";
import { Blog } from "@/types/blog";

interface BlogHeaderProps {
  blog: Blog;
  viewsCount: number;
}

export const BlogHeader = ({ blog, viewsCount }: BlogHeaderProps) => {
  return (
    <header className="mb-8">
      <h1 className="text-4xl md:text-5xl font-serif font-bold mb-6">{blog.title}</h1>
      <div className="flex flex-wrap items-center gap-6 text-gray-600">
        <div className="flex items-center gap-3">
          <div className="w-12 h-12 rounded-full overflow-hidden bg-gray-100 ring-2 ring-primary/20">
            {blog.author?.avatar_url ? (
              <img
                src={blog.author.avatar_url}
                alt={blog.author.name}
                className="w-full h-full object-cover"
                loading="lazy"
              />
            ) : (
              <User className="w-full h-full p-2 text-gray-400" />
            )}
          </div>
          <div>
            <span className="block font-medium text-gray-900">{blog.author?.name}</span>
            <span className="text-sm">Travel Writer</span>
          </div>
        </div>
        <div className="flex items-center gap-1">
          <Calendar className="w-4 h-4" />
          <span>{new Date(blog.created_at).toLocaleDateString()}</span>
        </div>
        {blog.reading_time && (
          <div className="flex items-center gap-1">
            <Clock className="w-4 h-4" />
            <span>{blog.reading_time} min read</span>
          </div>
        )}
        <div className="flex items-center gap-1">
          <Eye className="w-4 h-4" />
          <span>{viewsCount} views</span>
        </div>
      </div>
    </header>
  );
};