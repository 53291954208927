import { motion } from "framer-motion";
import { Card } from "@/components/ui/card";
import { useState } from "react";
import { Quote } from "lucide-react";

const testimonials = [
  {
    name: "Sarah Ahmed",
    location: "Karachi",
    text: "The AI recommendations were spot on! Found amazing places I never knew existed. The personalized itinerary made my trip so much easier to plan.",
    image: "https://images.unsplash.com/photo-1494790108377-be9c29b29330",
    rating: 5,
  },
  {
    name: "Ali Hassan",
    location: "Lahore",
    text: "Planning my trip was incredibly easy. The itinerary was perfect for my interests and the local insights helped me discover hidden gems.",
    image: "https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d",
    rating: 5,
  },
  {
    name: "Fatima Khan",
    location: "Islamabad",
    text: "A game-changer for exploring Pakistan. Every recommendation was thoughtful and the AI really understood what kind of experiences I was looking for.",
    image: "https://images.unsplash.com/photo-1438761681033-6461ffad8d80",
    rating: 5,
  },
];

const Testimonials = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <section className="py-24 bg-gradient-to-b from-white to-secondary/30">
      <div className="container mx-auto px-4">
        <div className="text-left mb-16">
          <motion.span
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            className="text-primary font-medium px-4 py-2 rounded-full bg-primary/10 inline-block mb-4"
          >
            Testimonials
          </motion.span>
          <motion.h2
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            className="text-3xl md:text-4xl font-serif font-bold mt-2"
          >
            What Our Travelers Say
          </motion.h2>
        </div>

        <div className="max-w-4xl mx-auto">
          <motion.div
            key={activeIndex}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            <Card className="p-8 md:p-12 bg-white/50 backdrop-blur-sm hover:bg-white/80 transition-all duration-300">
              <div className="flex flex-col md:flex-row items-start md:items-center gap-8">
                <div className="relative flex-shrink-0">
                  <img
                    src={testimonials[activeIndex].image}
                    alt={testimonials[activeIndex].name}
                    className="w-20 h-20 rounded-full object-cover ring-4 ring-primary/20"
                  />
                  <Quote className="absolute -bottom-2 -right-2 w-8 h-8 text-primary bg-white rounded-full p-1.5" />
                </div>
                <div className="flex-1">
                  <div className="flex gap-1 mb-4">
                    {[...Array(testimonials[activeIndex].rating)].map((_, i) => (
                      <svg
                        key={i}
                        className="w-5 h-5 text-yellow-400 fill-current"
                        viewBox="0 0 20 20"
                      >
                        <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                      </svg>
                    ))}
                  </div>
                  <p className="text-lg text-gray-700 mb-6 italic text-left">
                    "{testimonials[activeIndex].text}"
                  </p>
                  <div className="text-left">
                    <h4 className="font-semibold text-xl mb-1">
                      {testimonials[activeIndex].name}
                    </h4>
                    <p className="text-sm text-gray-500">
                      {testimonials[activeIndex].location}
                    </p>
                  </div>
                </div>
              </div>
            </Card>
          </motion.div>

          <div className="flex justify-center mt-8 gap-3">
            {testimonials.map((_, index) => (
              <button
                key={index}
                onClick={() => setActiveIndex(index)}
                className={`w-3 h-3 rounded-full transition-colors ${
                  index === activeIndex ? "bg-primary" : "bg-gray-300"
                }`}
              />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Testimonials;