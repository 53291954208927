import { Blog, SocialLinks } from "@/types/blog";
import { BlogSocialLinks } from "./BlogSocialLinks";

interface BlogContentProps {
  blog: Blog;
  socialLinks: SocialLinks | null;
}

export const BlogContent = ({ blog, socialLinks }: BlogContentProps) => {
  return (
    <>
      <div
        className="prose prose-lg max-w-none prose-headings:font-serif prose-headings:text-gray-900 prose-p:text-gray-700 prose-a:text-primary hover:prose-a:text-primary-dark prose-img:rounded-xl"
        dangerouslySetInnerHTML={{ __html: blog.content || "" }}
      />

      {blog.tags && blog.tags.length > 0 && (
        <div className="mt-12 pt-8 border-t">
          <div className="flex flex-wrap gap-2">
            {blog.tags.map((tag: string) => (
              <span
                key={tag}
                className="px-4 py-1.5 text-sm rounded-full bg-secondary/50 text-text font-medium hover:bg-secondary transition-colors cursor-pointer"
              >
                {tag}
              </span>
            ))}
          </div>
        </div>
      )}

      {blog.author?.bio && (
        <div className="mt-12 p-8 bg-gray-50 rounded-xl">
          <h2 className="text-2xl font-serif font-semibold mb-4">
            About the Author
          </h2>
          <p className="text-gray-700 mb-6">{blog.author.bio}</p>
          <BlogSocialLinks socialLinks={socialLinks} />
        </div>
      )}
    </>
  );
};