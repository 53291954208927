import { useState } from "react";
import { motion } from "framer-motion";
import { useSearchParams, useParams } from "react-router-dom";
import { Filter, MapPin } from "lucide-react";
import { useQuery } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import DestinationCard from "@/components/DestinationCard";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import DestinationsListSkeleton from "@/components/destination/DestinationsListSkeleton";

const Destinations = () => {
  const { slug: categorySlug } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const [priceRange, setPriceRange] = useState("all");

  const searchQuery = searchParams.get("search") || "";

  const { data: category } = useQuery({
    queryKey: ["category", categorySlug],
    queryFn: async () => {
      if (!categorySlug) return null;
      const { data, error } = await supabase
        .from("categories")
        .select("*")
        .eq("slug", categorySlug)
        .single();

      if (error) throw error;
      return data;
    },
    enabled: !!categorySlug,
  });

  const { data: destinations, isLoading } = useQuery({
    queryKey: ["destinations", categorySlug, searchQuery, priceRange],
    queryFn: async () => {
      let query = supabase
        .from("destinations")
        .select(`
          *,
          category:categories(name, slug)
        `)
        .eq('status', 'published');

      if (categorySlug) {
        const { data: categoryData } = await supabase
          .from("categories")
          .select("id")
          .eq("slug", categorySlug)
          .single();

        if (categoryData) {
          query = query.eq('category_id', categoryData.id);
        }
      }

      if (priceRange !== "all") {
        query = query.eq('price_range', priceRange);
      }

      if (searchQuery) {
        query = query.or(`title.ilike.%${searchQuery}%,description.ilike.%${searchQuery}%`);
      }

      const { data, error } = await query;
      if (error) throw error;
      return data;
    },
  });

  if (isLoading) {
    return (
      <div className="min-h-screen bg-gradient-to-br from-primary/5 to-secondary/5 pt-20">
        <div className="container mx-auto px-4 py-12">
          <div className="text-center mb-16">
            <h1 className="text-4xl md:text-5xl font-serif font-bold mb-6 text-text">
              {category ? category.name : "Explore All Destinations"}
            </h1>
            <p className="text-lg text-text/80 max-w-2xl mx-auto">
              {category?.description || "Discover the hidden gems and popular attractions across Pakistan"}
            </p>
          </div>
          <DestinationsListSkeleton />
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-br from-primary/5 to-secondary/5 pt-20">
      <div className="container mx-auto px-4 py-12">
        {/* Hero Section */}
        <div className="text-center mb-16">
          <motion.h1
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="text-4xl md:text-5xl font-serif font-bold mb-6 text-text"
          >
            {category ? category.name : "Explore All Destinations"}
          </motion.h1>
          <p className="text-lg text-text/80 max-w-2xl mx-auto">
            {category?.description || "Discover the hidden gems and popular attractions across Pakistan"}
          </p>
        </div>

        {/* Filters */}
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.2 }}
          className="bg-white/80 backdrop-blur-sm rounded-xl shadow-sm p-6 mb-8"
        >
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <label className="text-sm font-medium mb-2 block">Price Range</label>
              <Select
                value={priceRange}
                onValueChange={setPriceRange}
              >
                <SelectTrigger>
                  <SelectValue placeholder="Select price range" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="all">All Prices</SelectItem>
                  <SelectItem value="Budget">Budget</SelectItem>
                  <SelectItem value="Mid-Range">Mid-Range</SelectItem>
                  <SelectItem value="Luxury">Luxury</SelectItem>
                </SelectContent>
              </Select>
            </div>
            <div>
              <label className="text-sm font-medium mb-2 block">Search</label>
              <div className="relative">
                <Input
                  type="text"
                  placeholder="Search destinations..."
                  className="pl-10"
                  value={searchQuery}
                  onChange={(e) => setSearchParams({ search: e.target.value })}
                />
                <MapPin className="absolute left-3 top-1/2 -translate-y-1/2 w-4 h-4 text-gray-400" />
              </div>
            </div>
          </div>
        </motion.div>

        {/* Results */}
        {destinations && destinations.length > 0 ? (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {destinations.map((destination, index) => (
              <motion.div
                key={destination.id}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.1 }}
              >
                <DestinationCard
                  id={destination.slug}
                  image={destination.featured_image_url || ""}
                  title={destination.title}
                  description={destination.description || ""}
                  tags={destination.tags || []}
                  rating={destination.rating || 0}
                  location={destination.location || ""}
                />
              </motion.div>
            ))}
          </div>
        ) : (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            className="text-center py-12"
          >
            <h3 className="text-2xl font-serif font-semibold mb-4">No destinations found</h3>
            <p className="text-text/70">Try adjusting your search criteria</p>
          </motion.div>
        )}
      </div>
    </div>
  );
};

export default Destinations;