import { Skeleton } from "@/components/ui/skeleton";

const DestinationsListSkeleton = () => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
      {[1, 2, 3, 4, 5, 6].map((index) => (
        <div key={index} className="group bg-white/80 backdrop-blur-sm rounded-2xl overflow-hidden shadow-sm hover:shadow-lg transition-all duration-300">
          <div className="relative aspect-[4/3]">
            <Skeleton className="w-full h-full" />
            <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent opacity-0 group-hover:opacity-100 transition-opacity" />
          </div>
          
          <div className="p-6">
            <div className="flex items-center gap-2 text-sm text-gray-600 mb-2">
              <Skeleton className="w-4 h-4" />
              <Skeleton className="w-24 h-3" />
            </div>
            
            <Skeleton className="h-6 w-3/4 mb-2" />
            <Skeleton className="h-4 w-full mb-4" />
            
            <div className="flex flex-wrap gap-2 mb-4">
              {[1, 2, 3].map((tagIndex) => (
                <Skeleton key={tagIndex} className="h-6 w-16 rounded-full" />
              ))}
            </div>
            
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-1">
                <Skeleton className="w-4 h-4" />
                <Skeleton className="w-12 h-3" />
              </div>
              <Skeleton className="w-20 h-8 rounded-full" />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default DestinationsListSkeleton;