import { useQuery } from "@tanstack/react-query";
import { useNavigate, useParams } from "react-router-dom";
import { supabase } from "@/integrations/supabase/client";
import { motion } from "framer-motion";
import { Button } from "@/components/ui/button";
import { Helmet } from "react-helmet-async";
import { toast } from "sonner";
import { Share2 } from "lucide-react";

const SingleDestination = () => {
  const navigate = useNavigate();
  const { slug } = useParams();

  const { data: destination, isLoading } = useQuery({
    queryKey: ["destination", slug],
    queryFn: async () => {
      const { data, error } = await supabase
        .from("destinations")
        .select(`
          *,
          category:categories(name, slug),
          images:destination_images(*)
        `)
        .eq("slug", slug)
        .maybeSingle();

      if (error) throw error;
      if (!data) throw new Error("Destination not found");
      return data;
    },
  });

  const handleShare = async () => {
    try {
      await navigator.share({
        title: destination?.title,
        text: destination?.description,
        url: window.location.href,
      });
    } catch (error) {
      toast.error("Sharing failed. Try copying the URL manually.");
    }
  };

  if (isLoading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="w-16 h-16 border-4 border-primary border-t-transparent rounded-full animate-spin" />
      </div>
    );
  }

  if (!destination) {
    return (
      <div className="min-h-screen flex flex-col items-center justify-center">
        <h1 className="text-3xl font-serif font-bold mb-4">Destination Not Found</h1>
        <Button onClick={() => navigate("/destinations")}>View All Destinations</Button>
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>{destination.title} | Travel Destinations</title>
        <meta name="description" content={destination.description || ""} />
      </Helmet>

      <div className="min-h-screen bg-gradient-to-br from-primary/5 to-secondary/5 pt-20">
        <div className="container mx-auto px-4 py-8">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="bg-white rounded-xl shadow-xl overflow-hidden"
          >
            <div className="relative h-96">
              <img
                src={destination.featured_image_url || "/placeholder.svg"}
                alt={destination.title}
                className="w-full h-full object-cover"
              />
              <Button
                variant="ghost"
                size="icon"
                className="absolute top-4 right-4 bg-white/20 backdrop-blur-sm hover:bg-white/40"
                onClick={handleShare}
              >
                <Share2 className="w-5 h-5" />
              </Button>
            </div>

            <div className="p-8">
              <h1 className="text-4xl font-serif font-bold mb-4">{destination.title}</h1>
              <p className="text-gray-600 mb-6">{destination.description}</p>

              {destination.content && (
                <div
                  className="prose max-w-none"
                  dangerouslySetInnerHTML={{ __html: destination.content }}
                />
              )}

              {destination.tags && destination.tags.length > 0 && (
                <div className="flex flex-wrap gap-2 mt-6">
                  {destination.tags.map((tag: string) => (
                    <span
                      key={tag}
                      className="px-3 py-1 bg-primary/10 text-primary rounded-full text-sm"
                    >
                      {tag}
                    </span>
                  ))}
                </div>
              )}
            </div>
          </motion.div>
        </div>
      </div>
    </>
  );
};

export default SingleDestination;